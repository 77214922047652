.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: rgba(30, 30, 45, 0.8); 
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;
}

.nav {
    height: calc(var(--header-height) + 1.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2rem;
}

.nav__logo, .nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: color 0.3s ease;
}

.nav__logo:hover {
    color: var(--title-color-dark);
}

.nav__list {
    display: flex;
    column-gap: 2rem;
}

.nav__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: .3s;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.nav__link--linux {
    background-color: rgba(0, 122, 204, 0.1);
    border: 1px solid var(--title-color-dark);
    padding: 0.5rem 1rem;
}

.nav__link--linux:hover {
    background-color: var(--container-color);
    color: var(--title-color-dark);
}

.nav__icon {
    font-size: 1.2rem;
}

.nav__close, .nav__toggle {
    display: none;
}

.active-link, .nav__link:hover {
    color: var(--title-color-dark);
}

.scroll-header {
    box-shadow: 0 5px 15px rgba(0,0,0,0.15);
    background-color: rgba(30, 30, 45, 0.95);
}

@media screen and (max-width: 768px) {
    .header {
        height: var(--header-height);
    }

    .nav {
        height: 100%;
        justify-content: center;
    }

    .nav__menu, .nav__toggle {
        display: none;
    }

    .nav__logo {
        transform: scale(2);
        transition: transform 0.3s ease;
    }
}

@media screen and (max-width: 350px) {
    .nav__menu {
        padding: 2rem 0.2rem 4rem;
    }

    .nav__list {
        column-gap: 0;
    }
}