.footer {
  background-color: #1e1e2d; /* Dark blue background */
  color: #ffffff; /* White text color */
  font-family: 'Arial', sans-serif;
  padding-bottom: 20px;
}

.footer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ffffff; /* Slightly lighter blue border */
  padding-top: 10px;
}

.footer__content {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; /* Allows content to wrap on smaller screens */
}

.footer__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0; /* Adds spacing between sections on wrap */
}

.footer__title {
  margin-bottom: 10px;
  font-size: 18px;
}

.footer__contact, .footer__social {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Wrap links in smaller screens */
}

.footer__link, .footer__social-link {
  background-color: #29293d; /* Slightly lighter blue for buttons */
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 30px; /* Rounded edges */
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.footer__icon {
  margin-right: 8px;
}

.footer__bottom {
  text-align: center;
  color: #c0c0c0; /* Light grey text */
  width: 100%;
}

.footer__copy {
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer__content {
    flex-direction: column;
    align-items: center;
  }

  .footer__contact, .footer__social {
    justify-content: center;
    
  }

  .footer__link, .footer__social-link {
    padding: 8px 16px; /* Smaller padding on smaller screens */
  }

  .footer__title {
    font-size: 16px; /* Slightly smaller font size for mobile */
  }
}

/* Mobile-specific styles */
@media (max-width: 480px) {
  
  .footer__content {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 30px;
  }

  .footer__section {
    width: 45%; /* Each section takes up nearly half the width */
    align-items: flex-start;
  }

  .footer__contact {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .footer__social {
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }

  .footer__link, .footer__social-link {
    width: 100%; /* Full width buttons */
    padding: 10px 15px; /* Adjust padding */
    text-align: left; /* Align text to the left */
    font-size: 14px; /* Smaller font size */
  }

  .footer__icon {
    margin-right: 10px;
  }

  .footer__bottom {
    margin-top: 20px;
    text-align: center;
  }
}
