/* Loader.css */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  padding: var(--mb-1);
  font-family: var(--body-font);
}

.loader-content {
  background-color: var(--container-color);
  padding: var(--mb-1-5);
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 42rem;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.loader-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--mb-1);
}

.loader-title {
  font-size: var(--h2-font-size);
  color: var(--title-color);
  font-weight: var(--font-semibold);
}

.progress-bar {
  background-color: var(--background-color);
  border-radius: 0.375rem;
  overflow: hidden;
  height: 1rem;
  margin: var(--mb-1) 0;
}

.progress {
  background-color: var(--title-color-dark);
  height: 100%;
  transition: width 0.5s ease;
}

.progress-text {
  font-size: var(--small-font-size);
  color: var(--text-color);
  margin-top: var(--mb-0-5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 992px) {
  .loader-container {
    padding: var(--mb-0-75);
  }

  .loader-content {
    padding: var(--mb-1);
  }

  .loader-title {
    font-size: var(--h3-font-size);
  }

  .progress-text {
    font-size: var(--smaller-font-size);
  }
}

@media screen and (max-width: 768px) {
  .loader-content {
    max-width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .loader-content {
    padding: var(--mb-0-75);
  }

  .progress-text {
    font-size: var(--smallest-font-size);
  }
}
